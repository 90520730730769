import React from 'react';

import { Container, Grid, Typography } from '@material-ui/core';

import { CenterWave } from '../General/CenterWave';
import { FeatureCard } from './FeatureCard';

export const RoiBody = ({
	centerWaveBackground,
	centerWaveImagesMobile,
	centerWaveImagesDesktop,
	featuresTitle,
	featuresCards,
}) => {
	return (
		<>
			<CenterWave
				background={centerWaveBackground}
				stats={centerWaveImagesDesktop}
				roi
			/>
			<Container>
				<Typography
					variant='h2'
					color='primary'
					style={{ textAlign: 'center', padding: '2rem 0' }}>
					{featuresTitle}
				</Typography>
				<Grid container spacing={4} style={{ padding: '4rem 0' }}>
					{featuresCards.map((feature, index) => (
						<FeatureCard key={index} feature={feature} />
					))}
				</Grid>
			</Container>
		</>
	);
};
